import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Mosaic from 'react-photo-mosaic';
import useWebSocket from 'react-use-websocket';
import { shuffle } from 'lodash';

export default function MosaicFront() {

    const url = useParams();
    const [photos, setPhotos] = useState([]);
    const [endpoint, setEndpoint] = useState('');
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [flag, setFlag] = useState(false);

    const { lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}/mosaic?systemId=${url.dynamicRoute}`, {
        onOpen: () => console.log(`Connected to App WS`),
        onMessage: () => {
             //if (lastJsonMessage) console.log(lastJsonMessage)
        },
        onError: (event) => {
            console.error(event);
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 1000
    });

    useEffect(() => {
        setEndpoint(url.dynamicRoute)
    }, [])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (photos && photos.images) return;
            else {
                if (!shouldRedirect) setShouldRedirect(true);
            }
        }, 2000);

        return () => shouldRedirect ? null : clearTimeout(timeoutId);
    }, [photos, endpoint, shouldRedirect]);

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.length > 0 && photos.length < 1 && lastJsonMessage !== photos) {
            setPhotos(lastJsonMessage);
        }
    }, [lastJsonMessage])

    useEffect(() => {
        if (photos && photos.length > 0) {
            let obj = {
                images: shuffle(photos.map(element => encodeURI(element.media))).slice(0, 16), 
                width: ((window.innerHeight - 40) * 9)/16,
                height: window.innerHeight - 40,
                maxBlocksInRow: 4,
                spaceBetween: 7,
                secondaryImageStyle: { borderRadius: '0px' }
            }
            console.log(obj.images)
            if (!flag) {
                setPhotos(obj);
                setFlag(true)
            }
        }
    }, [photos]);

    if (shouldRedirect) {
        return <Navigate to={'/'} />;
    }

    return (
        <div className='mosaicContainer'>
            <Mosaic {...photos} />
        </div>
    )
}