import React, { useEffect, useState, useRef } from "react";
import { aprovacaoSend } from '../../services/stories/adminService';
import QRCodeGen from "../QRcodeGen/QRcodeGen";

/**
 * props 
 * - evento
 * - api
 * - endpoint
 * - updateApi
 * - handleAprovacao
 */
function ItensAdmin(props) {

    const sizeQr = 650;
    const btnDownloadQR = useRef();
    const canvasRef = useRef();

    const [evento, setEvento] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [linkQR, setLinkQR] = useState('#');
    const [qrImage, setQrImage] = useState(null);

    useEffect(() => {
        setEvento(props.evento);
        setEndpoint(props.endpoint);
    }, [props])

    useEffect(() => {
        const generateQRCode = async () => {
            if (evento && evento.evento.length > 0) {
                const url = process.env.REACT_APP_FRONT_URL + '/stories/envio/' + endpoint;
                try {
                    const generatedImage = <QRCodeGen url={url} size={sizeQr} />;
                    setQrImage(generatedImage);
                } catch (error) {
                    console.error("Erro ao gerar imagem QR:", error);
                }
            }
        };
        generateQRCode();
    }, [evento]);

    useEffect(() => {
        if (qrImage) {
            const newCanvas = document.createElement('canvas');
            newCanvas.width = sizeQr;
            newCanvas.height = sizeQr;

            const newContext = newCanvas.getContext('2d');

            const img = new Image();

            const svgBlob = new Blob([new XMLSerializer().serializeToString(canvasRef.current.firstChild)], { type: 'image/svg+xml' });
            const svgUrl = URL.createObjectURL(svgBlob);

            img.onload = () => {
                newContext.drawImage(img, 0, 0, sizeQr, sizeQr);

                if (canvasRef.current.parentElement) {
                    canvasRef.current.parentElement.replaceChild(newCanvas, canvasRef.current);
                }
                URL.revokeObjectURL(svgUrl);
                canvasRef.current = newCanvas;
            };
            img.src = svgUrl;
        }
    }, [qrImage, sizeQr]);

    function downloadQR() {
        const originalCanvas = canvasRef.current.firstChild;
        if (originalCanvas) {
            const originalContext = originalCanvas.getContext('2d');

            const originalWidth = originalCanvas.width;
            const originalHeight = originalCanvas.height;

            const padding = originalWidth / 10;

            const novoCanvas = document.createElement('canvas');
            const novoWidth = originalWidth + 2 * padding;
            const novoHeight = originalHeight + 2 * padding;
            novoCanvas.width = novoWidth;
            novoCanvas.height = novoHeight;

            const novoContext = novoCanvas.getContext('2d');

            novoContext.fillStyle = 'white';
            novoContext.fillRect(0, 0, novoWidth, novoHeight);

            novoContext.drawImage(originalCanvas, padding, padding, originalWidth, originalHeight);

            let image = novoCanvas.toDataURL('image/jpeg');

            const date = new Date();
            btnDownloadQR.current.download = `QR-code-${evento.evento.toLowerCase().replaceAll(' ', '-')}-${date.toLocaleDateString().replaceAll('/', '-')}-${date.toLocaleTimeString().slice(0, -3).replace(':', 'h')}`;
            setLinkQR(image);
        }
    }

    function aprovacao() {
        const token = localStorage.getItem('token');
        const comando = evento.aprova === 'auto' ? 'manual' : 'auto';
        aprovacaoSend(comando, endpoint, token)
            .then(res => {
                setEvento({
                    ...evento,
                    aprova: res
                })
                props.handleAprovacao(res);
            })
            .catch(err => console.error(err))
    }

    return (
        <div className="baixarQrAdmin">
            <div className='canvasBox' ref={canvasRef}>
                {qrImage}
            </div>
            <div className='downloadQR'>
                <button type="button"><a href={process.env.REACT_APP_FRONT_URL + '/stories/' + endpoint} target="_blank" >Tela de Exibição</a></button>
                {<button type="button"><a href={process.env.REACT_APP_FRONT_URL + '/mosaic/' + endpoint} target="_blank" >Mosaico</a></button>}
                <button type="button"><a href={process.env.REACT_APP_FRONT_URL + '/stories/envio/' + endpoint} target="_blank">Envio</a></button>
                <button type="button"><a href={linkQR} ref={btnDownloadQR} onClick={downloadQR} download>QR Code Envio</a></button>
                <button type="button" onClick={aprovacao}>{evento.aprova}</button>
            </div>
        </div>
    )
}

export default ItensAdmin;